<template>
  <b-modal id="sortingModal" title="sort insights" :no-close-on-esc=true :no-close-on-backdrop=true :hide-header-close=true
  @ok="handleOk" @cancel="handleCancel">
    <p> Drag the insight tiles into the correct order and click "Ok" to confirm </p>
    <draggable v-model="sortedList">
      <div class="d-flex justify-content-between border border-primary rounded-lg p-1 mt-1" v-for="insight in sortedList" :key="insight.id">
        <span>{{insight.title}}</span>
        <b-icon-arrow-down-up class="mt-1"/>
      </div>
    </draggable>
  </b-modal>
</template>

<script>
import draggable from 'vuedraggable';
export default {
  name: 'InsightSorter',
  components: {
    draggable
  },
  data () {
    return {
      sortedList: this.insightList
    };
  },
  mounted() {
    this.$bvModal.show('sortingModal');
  },
  props: {
    insightList: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleOk () {
      for (let index=0; index < this.sortedList.length; index++) {
        this.sortedList[index].sort_order = index + 1;
      }
      this.$bvModal.hide('sortingModal');
      this.$emit('insightSortingComplete', this.sortedList);

    },
    handleCancel() {
      this.$bvModal.hide('sortingModal');
      this.$emit('insightSortingCancelled');
    },
    
  }
};
</script>

<style scoped>

</style>